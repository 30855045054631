<template>
  <content>
    <div class="caixaContainer">
      <div class="lista">
        <b-tabs
          pills
          fill
          class="w-100"
          v-model="listSel"
        >
          <b-btn
            @click="findByComnanda"
            variant="none"
            block
            ><i class="fa fa-barcode mr-1" /> Buscar Comanda</b-btn
          >
          <b-tab
            title="Mesas Abertas"
            class="pt-3"
          >
            <template #title>
              <i class="fa fa-hand-holding" /> Abertas
            </template>
            <div
              class="item"
              v-for="mesa in mesaAberta"
              :key="mesa.id"
              :class="{
                selecionado: mesaSelecionada && mesaSelecionada.id == mesa.id,
              }"
              @click="selecionarMesa(mesa)"
            >
              <div class="row m-0 p-0">
                <div
                  class="col-12 col-sm-5 pl-0 pr-0 pl-sm-3 text-center text-sm-left"
                >
                  {{ mesa.mesa }}
                  <div v-if="mesa.juncao && mesa.juncao.length > 0">
                    <div
                      v-for="m in mesa.juncao"
                      :key="`mmJust${m.cod_mesa}${Math.random()}`"
                    >
                      <small
                        ><small
                          ><b-icon-plus /> {{ getMesa(m.cod_mesa).mesa }}</small
                        ></small
                      >
                    </div>
                  </div>
                  <div>
                    <!-- <small class="">Conta nº {{ mesa.id }}</small> -->
                    <div
                      class="mt-0 pt-0"
                      style="font-size: 8px"
                    >
                      Total: {{ (mesa.vl_total || 0) | currency }}<br />

                      Pago: {{ mesa.vl_pago || 0 | currency }} <br />
                      <span style="color: red"
                        >Restante:
                        {{
                          mesa.vl_total - (mesa.vl_pago || 0) || 0 | currency
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-sm-7 pr-0 pl-0 text-center text-sm-right"
                >
                  <small>
                    <small>
                      <b-icon-clock />
                      {{ mesa.dt_aberta | moment("HH:mm") }}
                      <b-icon-person class="ml-2" />
                      {{ mesa.usu }}
                      <div v-if="mesa.comandas && mesa.comandas.length > 0">
                        <div
                          v-for="cm in mesa.comandas"
                          :key="`mesa_Cmd_${mesa.cod_mesa}${cm.id}`"
                        >
                          <span
                            style="color: green"
                            v-if="cm.vl_subtotal <= cm.vl_pago"
                            ><b-icon-hand-thumbs-up-fill
                          /></span>
                          <i class="fa fa-barcode mr-1" /> {{ cm.nome }}
                        </div>
                      </div>
                    </small>
                  </small>
                </div>
                <div
                  v-if="mesa.dt_conta_solicitada"
                  class="col-12 bg-danger text-white p-3 text-center"
                >
                  <small>
                    <b-icon-calculator />
                    CONTA SOLICITADA
                  </small>
                </div>
                <div
                  v-if="mesa.dt_garcom_solicitado"
                  class="col-12 bg-warning p-2 text-center"
                >
                  <small>
                    <b-icon-person-circle />
                    Garçom solicitado
                  </small>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab
            title="Mesas Fechadas"
            class="pt-3"
          >
            <template #title> <i class="fa fa-history" /> Histórico </template>
            <b-btn
              @click="carregarMesasFechadas"
              variant="none"
              block
              v-if="!loading"
            >
              <i class="fa fa-sync" /> Atualizar
            </b-btn>
            <div v-if="loading">
              <b-skeleton
                type="card"
                height="100px"
                class="mb-2"
                v-for="n in 10"
                :key="n"
              >
              </b-skeleton>
            </div>
            <div
              class="item"
              v-for="mesa in fechadas"
              :key="`fecharas_` + mesa.id"
              :class="{
                selecionado: mesaSelecionada && mesaSelecionada.id == mesa.id,
                comDiferenca: !mesa.vl_pago || mesa.vl_total > mesa.vl_pago,
              }"
              @click="selecionarMesa(mesa, true)"
            >
              <div class="row m-0 p-0">
                <div
                  class="col-12 col-sm-6 pl-0 pr-0 pl-sm-3 text-center text-sm-left"
                >
                  {{ mesa.mesa }}
                  <small class="ml-2">Conta nº {{ mesa.id }}</small> <br />
                  <small>
                    <small>
                      Total: {{ mesa.vl_total | currency }}<br />
                      Pago:
                      {{ mesa.vl_pago }}
                    </small>
                  </small>
                </div>
                <div
                  class="col-12 col-sm-6 pr-0 pl-0 text-center text-sm-right"
                >
                  <small>
                    <small>
                      <b-icon-clock />
                      {{ mesa.dt_aberta | moment("HH:mm") }}
                      <b-icon-person class="ml-2" />
                      {{ mesa.usu }}
                      <br />
                      <span class="text-secondar="
                        >Fechadas ás
                        {{ mesa.dt_fechada | moment("HH:mm") }}</span
                      >
                      <span
                        class="text-danger"
                        v-if="!mesa.vl_pago || mesa.vl_total != mesa.vl_pago"
                      >
                        <br />
                        Difereça de Valor Pago
                      </span>
                      <span
                        class="text-success"
                        v-if="mesa.vl_pago && mesa.vl_total <= mesa.vl_pago"
                      >
                        <br />
                        Pagamento OK
                      </span>
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div
        class="mesaContent pr-0 pl-0 pr-sm-3 pl-sm-3 text-left"
        v-if="mesaSelecionada"
        style="background-color: white"
      >
        <mesa-new
          :mesa="mesaSelecionada"
          :mesaObj="mesaSelecionada"
          :data="data"
          :empresa="empresa"
          @updateMesa="
            (mesa) => {
              //carregarMesasFechadas();
              $emit('updateMesa', mesa);
            }
          "
          @mesaFechada="mesaFechada"
          :mesas="mesas"
          :mesaLayout="layout"
          :startContent="'Conta'"
          :hideMesaMenu="true"
          :comandaSelected="comandaSelected"
        />
        <!-- <mesa-detail
          :mesa="mesaSelecionada"
          :contaSel="true"
          @mesaFechada="mesaFechada"
          @carregar="carregarMesasFechadas"
        /> -->
      </div>
      <div
        v-else
        class="mesaContent text-center block p-5"
      >
        Nenhuma mesa selecionada
      </div>
    </div>
    <b-modal
      id="modal-find-comanda"
      hide-footer
    >
      <mesa-new-comandas-openeds
        :mesas="mesas"
        @mesa="selecionarMesa"
        @comanda="selectComanda"
      />
    </b-modal>
  </content>
</template>

<script>
// import MesaDetail from "./MesaDetail.vue";
import MesaLib from "../../libs/MesaLib";
import moment from "moment";
import MesaNew from "./MesaNew.vue";
import MesaNewComandasOpeneds from "./MesaNewComandasOpeneds.vue";
export default {
  components: {
    MesaNew,
    MesaNewComandasOpeneds,
    // MesaDetail
  },
  props: {
    mesas: Array,
    data: String,
    empresa: Object,
    layout: [Object, Array],
  },
  sockets: {
    connect() {
      // this.joinRoom();
    },
    solicitarLista() {
      console.log("SOLICITADO historico");
    },
  },
  mounted() {
    this.carregarMesasFechadas();
    this.$emit("updateMesas");
  },
  data() {
    return {
      mesaSelecionada: null,
      fechadas: [],
      listSel: 0,
      comandaSelected: null,
      loading: false,
    };
  },
  watch: {
    listSel: function () {
      this.mesaSelecionada = null;
      if (this.listSel == 1) {
        this.carregarMesasFechadas();
      }
    },
    empresa: function () {
      this.carregarMesasFechadas();
    },
    data: function () {
      this.carregarMesasFechadas();
    },
    mesas: {
      deep: true,
      handler() {
        // this.carregarMesasFechadas();
        console.log("RECEBEU");
        if (
          this.mesaSelecionada &&
          !this.mesaAberta.find(
            (x) => x.cod_mesa == this.mesaSelecionada.cod_mesa
          )
        ) {
          this.mesaSelecionada = null;
        }
        this.$forceUpdate();
      },
    },
  },
  computed: {
    mesaAberta: function () {
      return this.mesas
        .filter((x) => x.id && x.id > 0)
        .sort((a, b) => {
          return a.dt_garcom_solicitado && !b.dt_garcom_solicitado ? -1 : 1;
        })
        .sort((a, b) => {
          return a.dt_conta_solicitada && !b.dt_conta_solicitada ? -1 : 1;
        })
        .reduce((ret, vl) => {
          let has = ret.find(
            (x) => x.conta == vl.id || x.conta == vl.mesa_controle_id
          );
          if (has) {
            has.contas.push(vl);
          } else {
            ret.push({
              conta: vl.mesa_controle_id || vl.id,
              contas: [],
              uniao: vl.juncao.map((it) => it.cod_mesa),
              ...vl,
            });
          }
          return ret;
        }, []);
    },
  },
  methods: {
    selectComanda(comanda) {
      this.comandaSelected = comanda;
      let hasInside = this.mesaAberta.find(
        (x) => x.contas && x.contas.some((o) => o.cod_mesa == comanda.cod_mesa)
      );
      let mesa = null;
      if (hasInside) {
        mesa = hasInside;
      } else {
        mesa = this.mesaAberta.find((x) => x.cod_mesa == comanda.cod_mesa);
      }
      this.mesaSelecionada = mesa;
      this.$bvModal.hide("modal-find-comanda");
    },
    findByComnanda() {
      this.$bvModal.show("modal-find-comanda");
    },
    selecionarMesa(mesa, historico = false) {
      this.comandaSelected = null;

      if (!historico) {
        let hasInside = this.mesaAberta.find(
          (x) => x.contas && x.contas.some((o) => o.cod_mesa == mesa.cod_mesa)
        );
        if (hasInside) {
          mesa = hasInside;
        } else {
          mesa = this.mesaAberta.find((x) => x.cod_mesa == mesa.cod_mesa);
        }
      }
      this.mesaSelecionada = mesa;
    },
    mesaFechada() {
      this.selecionarMesa(null);
      this.carregarMesasFechadas();
    },
    async carregarMesasFechadas() {
      this.loading = true;
      // console.log('--->',this.empresa.cod_empresa, moment(this.data).format("YYYY-MM-DD"));
      this.fechadas = [];
      this.fechadas = await MesaLib.getHistorico(
        this.empresa.cod_empresa,
        moment(this.data).format("YYYY-MM-DD")
      );
      console.log("this.fechadas", this.fechadas, this.mesaSelecionada);
      if (this.mesaSelecionada) {
        let ms = this.fechadas.find((x) => x.id == this.mesaSelecionada.id);
        if (ms) {
          await this.selecionarMesa(ms);
        }
        this.$forceUpdate();
      }
      console.log("FECHADAS", this.fechadas);
      this.loading = false;
    },
    getMesa(cod_mesa) {
      return this.mesas.find((x) => x.cod_mesa == cod_mesa);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
</style>
